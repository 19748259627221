
import { computed, defineComponent, PropType } from 'vue';

import { getProviderImageUrl } from '@/domains/assignment';

import { UUID } from '@/domains/common';
import { Banner } from '@/types/banner';
import TrackMulticontentProvidersModal
  from '@/components/assignment/TrackMulticontentProvidersModal/TrackMulticontentProvidersModal.vue';
import { IContentProvider } from '@/domains/content-provider';
import { useRef } from '@/composables/useRef';

export default defineComponent({
  name: 'ProvidersButton',

  components: {
    TrackMulticontentProvidersModal,
  },

  props: {
    providers: {
      type: Array as PropType<IContentProvider[]>,
      required: true,
    },
  },

  setup(props) {
    const isMultipleProviders = computed(() => (props.providers.length > 1));
    const mainProvider = computed(() => {
      const [first = null] = props.providers;
      return first;
    });

    const [dialogProvidersRef] = useRef<Banner>();
    const openDialog = () => {
      dialogProvidersRef.value?.open();
    };

    const getProviderIcon = (uuid: UUID) => {
      if (uuid) {
        return getProviderImageUrl(uuid, '46x46');
      }
      return '';
    };

    return {
      isMultipleProviders,
      mainProvider,
      dialogProvidersRef,
      openDialog,
      getProviderIcon,
    };
  },
});
