
import Vue, { PropType } from 'vue';
import 'scorm-again';
import { createNamespacedHelpers } from 'vuex';

import { RawLocation } from 'vue-router';
import debounce from 'lodash/debounce';
import AtomHasNewVersionBanner from '@/components/catalogue/AtomHasNewVersionBanner/AtomHasNewVersionBanner.vue';
import ScormIFrame from '@/domains/scorm/ScormIFrame.vue';
import TTBaseSideButton from '@/components/ui/TTBaseSideButton.vue';
import UpButton from '@/components/ui/UpButton.vue';
import ScormViewDescription from '@/views/App/Assignments/Scorm/ScormView/ScormViewDescription.vue';
import ProvidersButton from '@/components/ui/ProvidersButton';
import { IAtom, AtomType } from '@/domains/atom';
import { UUID } from '@/domains/common';

import * as ScormService from '@/services/api/lxp-scorm';
import { IScormContent } from '@/services/api/lxp-scorm/types';
import { IButtonsState, IAssignmentTrack } from '@/domains/assignment';
import { ScormProgressStatus } from '@/domains/scorm';
import StepsButtons from '@/domains/assignment/components/StepsButtons/StepsButtons.vue';
import { ITrackSessionGetResponseData } from '@/services/api/tracks/types';

interface IBreadcrumb {
  text: string;
  to?: RawLocation;
  disabled?: boolean;
  'data-test-label'?: string;
}

interface IScormViewData {
  scorm: IScormContent | null,
  isLoading: boolean,
  updateScormDebounced: (data: string) => void,
  hasNewVersion: boolean,
}

const companyStore = createNamespacedHelpers('company');

// FIX: в track нет providers - надо дергать ручку атома
export default Vue.extend({
  name: 'ScormView',

  components: {
    ScormIFrame,
    TTBaseSideButton,
    UpButton,
    ScormViewDescription,
    ProvidersButton,
    StepsButtons,
    AtomHasNewVersionBanner,
  },

  inject: ['Names'],

  props: {
    track: {
      type: Object as PropType<IAtom>,
      default: () => ({} as IAtom),
    },

    buttonsState: {
      type: Object as PropType<IButtonsState>,
      default: () => ({ prev: { enabled: false } }),
    },

    isStepCompleted: {
      type: Boolean,
      default: false,
    },

    trackSession: {
      type: Object as PropType<ITrackSessionGetResponseData['trackSession']>,
      required: true,
    },
  },

  data(): IScormViewData {
    return {
      scorm: null,
      isLoading: false,
      updateScormDebounced: () => {},
      hasNewVersion: false,
    };
  },
  computed: {
    ...companyStore.mapGetters(['companyId']),
    trackSessionId(): string {
      return this.$route.params.trackSessionId;
    },

    playerSessionId(): number {
      return +this.$route.params.playerSessionId;
    },

    stepId(): UUID {
      return this.$route.params.stepId;
    },

    breadcrumbs(): IBreadcrumb[] {
      return [
        {
          text: this.$t('LearningTracks.title'),
          to: {
            name: this.Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-program-link',
        },
        {
          text: this.programTitle,
          to: {
            name: this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_VIEW,
            params: {
              playerSessionId: String(this.playerSessionId),
            },
          },
          'data-test-label': 'program-name-link',
        },
        {
          text: this.$t('LearningTracks.materials'),
          disabled: true,
          'data-test-label': 'program-materials',
        },
      ];
    },
    programTitle(): IAssignmentTrack['name'] {
      return this.track?.name || this.$t('Assignment.Course.defaultTrackName');
    },

    title(): IScormContent['name'] {
      return this.scorm?.name || this.$t('Assignment.Course.defaultTrackName');
    },

    description(): IScormContent['description'] {
      return this.scorm?.description || '';
    },

    progressMeta(): string | null | undefined {
      return this.scorm?.progress?.progressMeta;
    },

    AtomType(): typeof AtomType {
      return AtomType;
    },
  },
  watch: {
    playerSessionId() {
      this.fetchScorm();
    },
  },
  async created() {
    this.updateScormDebounced = debounce(this.updateScorm, 300);

    await this.fetchScorm();

    await this.checkNewVersion();
  },
  methods: {
    async fetchScorm() {
      this.isLoading = true;

      try {
        const { content } = await ScormService.scormSessionGet({ playerSessionId: this.playerSessionId });

        this.scorm = content;
      } catch (e: any) {
        this.$di.notify.error({ content: e.message });
        this.$router.go(-1);
      } finally {
        this.isLoading = false;
      }
    },

    nextStep() {
      this.sendAnalytic(this.$t('ScormView.nextStep'), 'go-next-step');
      this.$emit('step:next');
      this.$emit('next-step');
    },

    previousStep() {
      this.sendAnalytic(this.$t('ScormView.prevStep'), 'go-prev-step');
      this.$emit('step:prev');
      this.$emit('prev-step');
    },

    async updateScorm(data: string) {
      if (this.isStepCompleted || !this.playerSessionId) {
        return;
      }

      try {
        const request = {
          payload: { progressMeta: JSON.stringify(data) },
          playerSessionId: this.playerSessionId,
        };

        await ScormService.scormSessionUpdate(request);
      } catch (e: any) {
        console.error(e);
      }
    },
    changeStatus(status: string) {
      if (status === ScormProgressStatus.COMPLETED || status === ScormProgressStatus.PASSED) this.complete();
    },
    async complete() {
      try {
        await ScormService.scormSessionComplete({ playerSessionId: this.playerSessionId });
        this.$emit('step:completed', { stepId: this.stepId });
      } catch (e: any) {
        console.error(e);
      }
    },
    sendAnalytic(eventValue: string, eventLabel: string) {
      const data = {
        companyId: this.companyId,
        playerSessionId: this.playerSessionId,
        stepId: this.stepId,
        trackSessionId: this.trackSessionId,
      };
      this.$di.tmt.sendEvent('send', 'click', eventValue, eventLabel, 'internallLink', data);
    },

    backButtonClickHandler() {
      this.sendAnalytic(this.$t('TTBackButton.title'), 'go-back');
      this.$router.back();
    },

    async checkNewVersion() {
      const { hasNewVersion = false } = await ScormService.scormCheckNewVersion({
        playerSessionId: this.playerSessionId,
      });

      this.hasNewVersion = hasNewVersion;
    },
  },
});
