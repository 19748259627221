
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ScormViewDescription',
  props: {
    text: {
      type: String as PropType<string>,
      default: '',
    },
  },
  data() {
    return {
      expanded: false as boolean,
    };
  },
  computed: {
    expandLabel(): string {
      return this.expanded
        ? this.$t('ScormViewDescription.collapse')
        : this.$t('ScormViewDescription.expand');
    },
  },
});
