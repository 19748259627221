
import Vue, { PropType } from 'vue';

type ButtonType = 'primary' | 'secondary';

export default Vue.extend({
  name: 'TTBaseSideButton',
  props: {
    title: {
      type: String,
      default: 'Создать',
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<ButtonType>,
      default: 'secondary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestFootnote: {
      type: String,
      default: '',
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
    href: {
      type: String as PropType<string>,
      default: undefined,
    },
  },
  computed: {
    buttonBackgroundColor(): string {
      return this.type === 'primary' ? 'tt-light-blue' : 'tt-light-mono-0';
    },
    iconColor(): string {
      return this.type === 'primary' ? 'tt-light-mono-0' : '';
    },
  },
});
